import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './theme/styles.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import AppConfig from './AppConfig';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Create a query client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

window.Buffer = window.Buffer || require('buffer').Buffer;

root.render(
  <>
    {AppConfig.env === 'production' ? (
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ChakraProvider>
      </Provider>
    ) : (
      <React.StrictMode>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </ChakraProvider>
        </Provider>
      </React.StrictMode>
    )}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
