import { extendTheme } from '@chakra-ui/react';
// import { theme as base } from '@chakra-ui/react';
// ${base.fonts?.heading}

// Global style overrides
import styles from './styles';

// Component style overrides
import Button from './components/button';
import Card from './components/card';
import Input from './components/input';
import Textarea from './components/textArea';
import Divider from './components/divider';
import Tabs from './components/tabs';
import Checkbox from './components/checkbox';
import Radio from './components/radio';

import configs from 'configs.json';

const overrides = {
  styles,
  fonts: {
    body: `Inter, sans-serif`,
    heading: `Outfit, sans-serif`
  },

  colors: configs.colors,

  radii: {
    none: '0',
    default: '20px',
    brandSmall: '12px',
    full: '999px'
  },
  components: {
    Button,
    Card,
    Input,
    Textarea,
    Divider,
    Tabs,
    Checkbox,
    Radio
  }
};

export default extendTheme(overrides);
